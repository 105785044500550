// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: true,
//   url: 'https://app.accreditloan.com/Webservice/',
//   url1: 'https://app.accreditloan.com/api/',
//   myInfoUrl: 'https://production.accreditloan.com/callback?uid='
// };

//  export const environment = {
//   production: false,
//   url: 'http://178.128.53.172:8000/Webservice/',
//   url1: 'http://178.128.53.172:8000/api/',
//   myInfoUrl: 'https://staging.accreditloan.com/callback?uid='
// };

export const environment = {
  production: true,
  url: 'https://app.bestcredit.sg/bestcredit-admin/index.php/Webservice/',
  url1: 'https://app.bestcredit.sg/bestcredit-admin/index.php/api/',
  myInfoUrl: 'https://production.sivren.org/callback_PROD-201605730G-BESTCREDIT-LOANAPPLN?uid=',
};

/*
export const environment = {
  // production: true,
  // url: 'https://app.accreditloan.com/bc/bestcredit-admin/index.php/Webservice/',
  // url1: 'https://app.accreditloan.com/bc/bestcredit-admin/index.php/api/',
  // myInfoUrl: 'https://production.accreditloan.com/callback?uid='

  //  production: false,
  //url: 'https://mobile.sivren.org/bc/bestcredit-admin/index.php/Webservice/',
  //url1: 'https://mobile.sivren.org/bc/bestcredit-admin/index.php/api/',
  //myInfoUrl: 'https://staging.accreditloan.com/callback?uid=',

  // production: false,
  // url: 'http://192.168.0.8/bestcredit-admin/index.php/Webservice/',
  // url1: 'http://192.168.0.8/bestcredit-admin/index.php/api/',
  // myInfoUrl: 'https://staging.sivren.org/callback?uid=',
};
*/
// export const environment = {
//   production: true,
//   url: 'https://app.accreditloan.com/Webservice/',
//   url1: 'https://app.accreditloan.com/api/',
//   myInfoUrl: 'https://production.accreditloan.com/callback?uid='
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
